var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-text',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.score),callback:function ($$v) {_vm.score=$$v},expression:"score"}},[_c('v-radio',{attrs:{"label":"Punkte","value":false}}),_c('v-radio',{attrs:{"label":"Score","value":true}})],1),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.sum),callback:function ($$v) {_vm.sum=$$v},expression:"sum"}},[_c('v-radio',{attrs:{"label":_vm.score ? 'Gesamt' : 'Durchschnitt',"value":true}}),_c('v-radio',{attrs:{"label":"Einzelne Wettkämpfe","value":false}})],1),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.discipline),callback:function ($$v) {_vm.discipline=$$v},expression:"discipline"}},_vm._l((_vm.disciplines),function(d){return _c('v-radio',{key:d._id,attrs:{"label":d.name,"value":d._id}})}),1)],1)],1),_c('base-material-card',{attrs:{"icon":"mdi-alarm-multiple","title":"Ranglisten","color":"red"}},[_c('v-text-field',{attrs:{"label":"Filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-data-table',{attrs:{"items":_vm.topathletes,"headers":[
        { text: `Platz${_vm.filter !== '' ? ' gesamt' : ''}`, value: 'place', sortable: false },
        _vm.filter !== '' ? { text: 'Platz im Filter', value: 'pic', sortable: false } : undefined,
        { text: 'Turner', value: 'person', sortable: false },
        { text: 'Mannschaft', value: 'team.name', sortable: false },
        { text: 'Liga', value: 'league.shortName', sortable: false },
        !_vm.score ? { text: 'Punkte', value: 'final', sortable: false } : {},
        _vm.score ? { text: 'Score', value: 'score', sortable: false } : {},
        _vm.sum ? { text: 'Wettkämpfe', value: 'count', sortable: false } : {},
      ].filter(r => !!r),"hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.person",fn:function({item}){return [_vm._v(" "+_vm._s(_vm._f("person")(item.person))+" ")]}},{key:"item.final",fn:function({item}){return [_vm._v(" "+_vm._s(_vm._f("float3")(item.final))+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }