<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card>
      <v-card-text>
        <v-radio-group
          v-model="score"
          row
        >
          <v-radio
            label="Punkte"
            :value="false"
          />
          <v-radio
            label="Score"
            :value="true"
          />
        </v-radio-group>
        <v-radio-group
          v-model="sum"
          row
        >
          <v-radio
            :label="score ? 'Gesamt' : 'Durchschnitt'"
            :value="true"
          />
          <v-radio
            label="Einzelne Wettkämpfe"
            :value="false"
          />
        </v-radio-group>
        <v-radio-group
          v-model="discipline"
          row
        >
          <v-radio
            v-for="d in disciplines"
            :key="d._id"
            :label="d.name"
            :value="d._id"
          />
        </v-radio-group>
      </v-card-text>
    </v-card>
    <base-material-card
      icon="mdi-alarm-multiple"
      title="Ranglisten"
      color="red"
    >
      <v-text-field
        v-model="filter"
        label="Filter"
      />
      <v-data-table
        :items="topathletes"
        :headers="[
          { text: `Platz${filter !== '' ? ' gesamt' : ''}`, value: 'place', sortable: false },
          filter !== '' ? { text: 'Platz im Filter', value: 'pic', sortable: false } : undefined,
          { text: 'Turner', value: 'person', sortable: false },
          { text: 'Mannschaft', value: 'team.name', sortable: false },
          { text: 'Liga', value: 'league.shortName', sortable: false },
          !score ? { text: 'Punkte', value: 'final', sortable: false } : {},
          score ? { text: 'Score', value: 'score', sortable: false } : {},
          sum ? { text: 'Wettkämpfe', value: 'count', sortable: false } : {},
        ].filter(r => !!r)"
        hide-default-footer
        :items-per-page="-1"
        :mobile-breakpoint="0"
      >
        <template #item.person="{item}">
          {{ item.person | person }}
        </template>
        <template #item.final="{item}">
          {{ item.final | float3 }}
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'ranking',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Event: {},
    DisciplineFind: [],
    score: false,
    sum: true,
    discipline: 0,
    filter: ''
  }),

  computed: {
    disciplines () {
      return [{ name: this.score ? 'Beste Scorer' : 'Sechskämpfer', _id: 0 }, ...this.DisciplineFind.filter(d => d.regular)]
    },
    topathletes () {
      let tmp = this.Event.topathletes?.slice() || []

      if (this.score) tmp = tmp.sort((a, b) => a.score > b.score ? -1 : 1)
      else tmp = tmp.sort((a, b) => a.final > b.final ? -1 : 1)

      tmp = tmp.map((a, i, arr) => ({
        ...a,
        name: a.person?.familyName + ', ' + a.person?.givenName,
        place: arr.filter(a2 => this.score ? a2.score > a.score : a2.final > a.final).length + 1
      }))

      if (this.filter !== '') {
        tmp = tmp.filter(a =>
          !!a.name.match(new RegExp(this.filter, 'i')) ||
          !!a.team?.name?.match(new RegExp(this.filter, 'i')) ||
          !!a.league?.name?.match(new RegExp(this.filter, 'i')) ||
          !!a.league?.shortName?.match(new RegExp(this.filter, 'i'))
        )
      }

      return tmp.slice(0, 100).map((a, i, arr) => ({
        ...a,
        pic: arr.filter(a2 => this.score ? a2.score > a.score : a2.final > a.final).length + 1
      }))
    }
  },

  watch: {
    discipline () {
      this.Event = {}
    },
    score () {
      this.Event = {}
    },
    sum () {
      this.Event = {}
    }
  },

  apollo: {
    DisciplineFind: {
      query: gql`
        query { DisciplineFind { _id name regular }}
      `
    },
    Event: {
      query: gql`
        query($id: UUID!, $discipline: UUID, $score: Boolean, $sum: Boolean) {
          Event(id: $id) {
            _id
            ... on StbM2021 {
              name
              topathletes(sum: $sum, score: $score, discipline: $discipline) {
                person { _id givenName familyName }
                team { _id name }
                league { _id shortName name }
                count
                final
                score
              }
            }
          }
        }
      `,
      variables () {
        return {
          id: this.id,
          discipline: this.discipline || null,
          score: this.score,
          sum: this.sum
        }
      }

    }
  }
}
</script>

<style scoped>

</style>
